
import { Vue, Prop, Component, PropSync } from 'vue-property-decorator';
import IconMenu from '@/components/gsk-components/menu/IconMenu.vue';
import { MenuOption } from '@/components/gsk-components/menu/menu.types';
import uniq from 'lodash/uniq';

@Component({
  components: {
    IconMenu,
  },
})
export default class TablePagination extends Vue {
  @Prop({ type: Number, required: true }) dataLength!: number;
  @PropSync('page', { type: Number, required: true }) pageNumber!: number;
  @PropSync('perPage', { type: Number, required: true }) resultsPerPage!: number;

  get totalResults() {
    return this.dataLength;
  }

  public get hideBack(): boolean {
    return this.pageNumber < 2;
  }

  public get hideForward(): boolean {
    const maxPage = this.totalResults / this.resultsPerPage;
    return (this.pageNumber >= maxPage);
  }

  defaultPerPageOptions: number[] = [10, 25, 50, 75, 100];

  get options(): MenuOption[] {
    return uniq(this.defaultPerPageOptions.concat(this.resultsPerPage))
      .sort((a, b) => a - b)
      .map(option => ({
        key: option.toString(),
        text: option.toString(),
      }));
  }

  get range() {
    if (this.totalResults === 0) {
      return '0-0';
    }
    let end = String(this.pageNumber * this.resultsPerPage);
    if (this.pageNumber * this.resultsPerPage > this.totalResults) {
      end = String(this.totalResults);
    }
    const range = String(1 + (this.pageNumber - 1) * this.resultsPerPage) + '-' + end;
    return range;
  }

  setResults(option: MenuOption) {
    this.resultsPerPage = +option.text;
  }

  back() {
    this.pageNumber -= 1;
  }

  forward() {
    this.pageNumber += 1;
  }
}
