

import { Component, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import { ValidationObserver } from 'vee-validate';
import { RawLocation } from 'vue-router';
import { RpaOpsModule } from '@/features/om27/store/om27.module';
import { UserPreferences } from '@/features/om27/types/om27.types';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import ScheduledReportsGrid from '@/features/om27/components/ScheduledReportsGrid.vue';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { openErrorSnackbar, openSuccessSnackbar } from '@/utils/components';
import { RouteNames } from '@/constants';

@Component({
  components: {
    Promised,
    Grid,
    GridCell,
    ScheduledReportsGrid,
    ValidatedFormDialog,
    GDialog,
  },
})
export default class Om27ScheduledReportingList extends Vue {

  isDeleteReportDialogOpen = false;
  deletingReportName: string | null = null;

  createReportRoute: RawLocation = {
    name: RouteNames.RpaOpsScheduledReportingCreate,
  };

  $refs!: {
    parametersDialog: InstanceType<typeof ValidationObserver>;
  };

  viewingReportName: string | null = null;
  isReportParametersDialogOpen = false;
  reportParametersDialogFormModel = [
    {
      key: 'mudId',
      type: 'people-picker',
      value: [],
      label: 'Mud Id',
      validation: {
        rules: {
          required: true,
        },
      },
      attrs: {
        singleSelect: true,
        'max-values': '1'
      },
    },
    {
      key: 'startDateUTC',
      type: 'text',
      value: '',
      label: 'Start Date (UTC)',
      validation: {
        rules: {
          required: true,
        },
      },
      attrs: {
        placeholder: 'YYYY-MM-DD',
      },
      customValidator: this.validateDateInput,
    },
    {
      key: 'endDateUTC',
      type: 'text',
      value: '',
      label: 'End Date (UTC)',
      validation: {
        rules: {
          required: true,
        },
      },
      attrs: {
        placeholder: 'YYYY-MM-DD',
      },
      customValidator: this.validateDateInput,
    },
  ];

  validateDateInput(dateString: string): Promise<string> {
    const value = String(dateString).trim();
    if (!value) {
      return Promise.resolve('Please enter a valid date in format YYYY-MM-DD');
    }
    const pattern = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
    let valid = value.match(pattern) && String(new Date(value)) !== 'Invalid Date';
    return Promise.resolve(valid ? '' : 'Please enter a valid date in format YYYY-MM-DD');
  }

  onDialogClosed() {
    this.$nextTick(() => {
      this.$refs.parametersDialog.reset();
    });
  }

  goToViewReport(reportName: string, parameters: any = {}) {
    const url = new URL(`${location.origin}/rpa-ops/scheduled-reporting/view/${reportName}`);
    url.searchParams.append('mudId', parameters.mudId || '');
    url.searchParams.append('startDateUTC', parameters.startDateUTC || '');
    url.searchParams.append('endDateUTC', parameters.endDateUTC || '');
    window.open(url, '_blank');
  }

  onSubmitParameters() {
    this.isReportParametersDialogOpen = false;
    const [mudId] = this.reportParametersDialogFormModel.find(f => f.key === 'mudId')?.value as any[];
    const startDateUTC = String(this.reportParametersDialogFormModel.find(f => f.key === 'startDateUTC')?.value);
    const endDateUTC = String(this.reportParametersDialogFormModel.find(f => f.key === 'endDateUTC')?.value);
    this.$nextTick(() => {
      this.goToViewReport(String(this.viewingReportName), {
        mudId: mudId?.mudId,
        startDateUTC,
        endDateUTC,
      });
    });
  }

  onViewReport(data: { reportName: string, parameterized: boolean }) {
    if (data.parameterized) {
      this.isReportParametersDialogOpen = true;
      this.viewingReportName = data.reportName;
      return;
    }
    this.goToViewReport(data.reportName);
  }

  onEditReport(data: { reportName: string }) {
    const editReportRoute: RawLocation = {
      name: RouteNames.RpaOpsScheduledReportingEdit,
      params: {
        reportName: data.reportName
      },
    };
    this.$router.push(editReportRoute);
  }

  onDeleteReport(data: { reportName: string }) {
    this.deletingReportName = data.reportName;
    this.isDeleteReportDialogOpen = true;
  }

  onDeleteReportDialogClosed() {
    this.isDeleteReportDialogOpen = false;
    this.deletingReportName = null;
  }

  async onDeleteReportDialogConfirmed() {
    if (this.deletingReportName) {
      try {
        await RpaOpsModule.deleteScheduledReport(this.deletingReportName);
        openSuccessSnackbar.call(this, `Report ${this.deletingReportName}, successfully deleted.`);
      } catch (ex: any) {
        let message = ex;
        if ('response' in ex) {
          message = ex.response?.data?.message || ex;
        }
        openErrorSnackbar.call(this, message);
      }
    }
    this.onDeleteReportDialogClosed();
  }

  get scheduledReports() {
    return RpaOpsModule.scheduledReports;
  }

  get timezone(): UserPreferences['preferred_timezone'] {
    return RpaOpsModule.userPreferences.preferred_timezone;
  }

  promise: Promise<any> | null = null;
  created() {
    this.promise = RpaOpsModule.getScheduledReportList();
  }
}

